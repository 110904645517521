<template>
  <div class="brief">
		<img src="../assets/img/img.png" alt="">
		<img class="tip" src="../assets/img/download.png" alt="">

		<!-- 参考文献 -->
		<Reference :info="refInfo"></Reference>
	</div>
</template>

<script>
import Reference from '../components/Reference'
export default {
  data() {
    return {
			refInfo: '<ul><li>1. 中华医学会呼吸病学分会哮喘学组. 咳嗽的诊断与治疗指南 (2015). 中华结核和呼吸杂志.2016,39(5):323-354.</li><li>2. Peter G Gibson, Anne E Vertigan. Management of chronic refractory cough. BMJ. 2015 Dec 14;351:h5590. </li><li>3. Based on Irwin R et al. Classification of Cough as a Symptom in Adults and Management Algorithms: CHEST Guideline and Expert Panel Report. Chest. 2018;153(1):196-209.</li></ul>'
		};
  },
	components: {
		Reference
	},
  created() {
		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, module_id: 2, page: this.$route.path})
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {}
};
</script>

<style scoped lang="scss">
.brief{
	width: 100%;
	position: relative;
	margin-bottom: 50px;
	// padding-bottom: 50px;
	>img{
		width: 100%;
		pointer-events: initial;
	}
	.tip{
		width: 88%;
		position: absolute;
		bottom: 30px;
		left: 6%;
		pointer-events: none;
	}
}
</style>
